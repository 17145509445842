//------------------------------------------------------------layout
.header {
	@extend %transition_background;

	position: fixed;
	top: 0;
	left: 0;

	@include bp($point_2, min) {
		height: $height_header;
	}

	@include bp($point_3) {
		height: 8em;
	}

	width: 100%;
	padding: 0 $gl_indent;

	font-size: 1rem;

	will-change: transform;

	@include z-index(header);

	&:before {
		@extend %transition_opacity;

		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 100%;
		height: 100%;

		background-color: $white;
		box-shadow: 0 1px 1em 0 rgba($black, .4);

		opacity: 0;
	}

	//&:before {
	//	position: absolute;
	//}

	&.scroll_mod {
		&:before {
			opacity: 1;
		}
	}

}

.header_in {
	@extend %global_flex_block_row_wrap_flex-start_center;

	position: relative;

	height: 100%;
}

.header_logo_w {
	width: 21.4em;

	
	@include bp($point_2, min) {
		margin-right: 7.8em;
	}
	

	@include bp($point_3) {
		font-size: 7px;
	}
}

.header_logo {
	@extend %transition_opacity;
	display: block;
	width: 100%;

	text-decoration: none;

	&:hover, &:active, &:focus {
		text-decoration: none;
		opacity: .8;
	}
}

.logo_img {
	display: block;
	width: 100%;
	height: auto;
}

.logo_text_w {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: -1.2em;

	text-align: right;
}

.logo_text {
	display: block;
	width: 100%;
	max-width: 18.6em;

	color: $gray_4;
	font-family: $font_1;
	font-size: 1.2em;
	font-weight: 700;
	line-height: (1.5/1.2);
	text-transform: uppercase;
}

.header_nav {
	@include bp($point_2, min) {
		position: absolute;
		top: 50%;
		left: 50%;

		transform: translate(-50%, -50%);

		body.inner_mod & {
			position: static;
			transform: none;
		}
	}

	@include bp($point_3) {
		z-index: 100;

		position: fixed;
		top: 0;
		left: 0;

		width: 100%;
		height: 100vh;
		padding: 8em 0;

		transition: transform .3s ease;
		will-change: transform;

		transform: translateY(-100%);
		pointer-events: none;

		background-color: $white;

		body.menu_open & {
			pointer-events: auto;
			transform: translateY(0);
		}
	}


}

.header_nav_list {
	@extend %global_flex_block_row_wrap_flex-start_center;

	padding: 0;

	list-style: none;

	@include bp($point_2, min) {
		margin: 0 -2.2em;
	}

	@include bp($point_3) {
		justify-content: center;
		flex-flow: column nowrap;
		height: 100%;
		max-height: 100%;

		overflow-x: hidden;
		overflow-y: auto;
	}
}

.header_nav_item {
	position: relative;

	@include bp($point_2, min) {
		padding: 0 2.2em;
	}

	@include bp($point_3) {
		width: 100%;
		padding: 0 $gl_indent;
		margin-bottom: 2em;

		text-align: center;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.header_nav_link {
	@extend %transition_color;

	position: relative;

	text-decoration: none;
	color: $gray_5;
	font-family: $font_1;
	font-size: 1.5em;
	font-weight: 700;
	line-height: .8;
	text-transform: uppercase;
	letter-spacing: .038rem;

	
	@include bp($point_2, min) {
		&:before {
	
			position: absolute;
			top: (-2.6em/1.5);
			left: 0;
	
			content: '';
	
			width: 100%;
			height: 1px;
	
			background-color: $accent;
	
			pointer-events: none;
			opacity: 0;

			transition: opacity .3s ease;
			will-change: opacity;
		}
	}
	

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $accent;
	}

	&.active_link {
		color: $accent;

		
		@include bp($point_2, min) {
			&:before {
				opacity: 1;
			}
		}
		
	}
}

.header_side_w {
	@extend %global_flex_block_row_wrap_flex-start_center;

	margin-left: auto;
}
//------------------------------------------------------------layout###
.header_lang_w {
	@include bp($point_2, min) {
		margin: 0 7.4em 0 0;
	}
	@include bp($point_3) {
		margin: 4px 2.2em 0 0;
	}
}

.dropdown_lang {
	position: relative;

	width: 100%;
	max-width: 4.4em;
	margin: 0;
	padding: 0;

	list-style: none;
}

.dropdown_lang_item {
	position: relative;

	width: 100%;
	padding-right: 2em;

	&:after {
		@extend %transition_transform;
		position: absolute;
		top: 0;
		right: 0;

		content: '';

		width: 1em;
		height: 1em;

		background-image: url("../i/decors/arrow_down.svg");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		opacity: .4;

		.dropdown_lang:hover & {
			transform: rotate(-180deg);
		}
	}
}

.dropdown_lang_title {
	//text-decoration: none;
	color: $teal_1;
	font-family: $font_1;
	font-size: 1.5em;
	font-weight: 700;
	line-height: .8;
	text-transform: uppercase;
	letter-spacing: .038rem;

	cursor: pointer;

	//&:hover, &:active, &:focus {
	//	text-decoration: none;
	//}
}

.dropdown_lang_sub_list {
	@extend %transition_opacity;

	position: absolute;
	width: 100%;
	left: 0;
	top: 100%;

	opacity: 0;
	pointer-events: none;

	background-color: $teal_1;

	.dropdown_lang:hover & {
		opacity: 1;
		pointer-events: auto;
	}
}

.dropdown_lang_sub_list_item {
	padding: 0 4px;
}

.dropdown_lang_sub_list_link {
	text-decoration: none;
	color: $white;
	font-family: $font_1;
	font-size: 1.5em;
	font-weight: 700;
	line-height: (2.6/1.5);
	text-transform: uppercase;
	letter-spacing: .038rem;

	&:hover, &:active, &:focus {
		text-decoration: none;
	}
}

.header_phone {
	@extend %transition_color;

	text-decoration: none;

	color: $teal_1;
	font-family: $font_1;
	font-weight: 700;
	line-height: .8;
	text-transform: uppercase;
	letter-spacing: .075rem;

	@include bp($point_2, min) {
		margin-bottom: (1em/3);

		font-size: 3em;
	}

	@include bp($point_3) {
		font-size: 1.6em;
	}

	
	@include bp($point_4 - 1) {
		display: none;
	}
	

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $accent;
	}

}

//---------------------------------------------trigger
@mixin user_menu_butt_line {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: .4rem;
	margin-top: -.2rem;
	background: $accent;
}

.menu_trigger {
	z-index: 110;

	position: relative;
	display: none; // hidden on wide screens
	height: 2.6em;
	width: 3.8em;
	
	
	@include bp($point_4, min) {
		margin-left: 2.2em;
	}
	

	text-transform: uppercase;

	color: transparent;
	cursor: pointer;

	@include bp($point_3) {
		display: block; // appear on tablets
	}

	&:before {
		content: '';
		transform: translate(0, -1rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&:after {
		content: '';
		transform: translate(0, 1rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&.active_mod {
		&:before {
			transform: (rotate(45deg) translate(0, 0));
		}

		&:after {
			transform: (rotate(-45deg) translate(0, 0));
		}
	}
}

.menu_trigger_decor {
	transition: all .3s ease;

	.active_mod & {
		opacity: 0;
		transform: translate(100%, 0);
	}

	@include user_menu_butt_line;
}

//---------------------------------------------trigger###