.section_banner {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	
	@include bp($point_3) {
		margin: 0 (-$gl_indent);
	}
	
}

.section_banner_col {
	@include bp($point_2, min) {
		width: 50%;
	}

	@include bp($point_3) {
		width: 100%;

		&:first-child {
			order: 2;
		}

		&:last-child {
			order: 1;
		}
	}
}

.section_banner_content {
	position: relative;

	width: 100%;
	height: 100%;
	
	@include bp($point_2, min) {
		padding: 9.6em 6.8em 6.6em;
	}

	
	@include bp($point_3) {
		padding: 6em $gl_indent;
	}
	
	

	background-color: rgba($teal_1, .9);

	overflow: hidden;

	&:before {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 68.4em;
		height: 64.2em;

		background-image: url("../i/decors/banner_gradient.png");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 50% 50%;
	}
}

.section_banner_content_in {
	z-index: 2;

	position: relative;
	width: 100%;
	
	@include bp($point_2, min) {
		max-width: 59em;
	}
	
}