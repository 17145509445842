.footer {
	z-index: 1;

	position: relative;

	width: 100%;
	flex: 0 0 auto;
	padding: 0 $gl_indent;

	font-size: 1rem;

	will-change: transform;

	&:after {
		z-index: 2;

		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 100%;
		height: 100%;

		background-color: $teal_1;
	}

	body.index_mod & {
		&:before {
			z-index: 1;
			position: absolute;
			left: 0;
			bottom: 0;

			content: '';

			width: 100%;
			height: 49.8rem;

			background-image: url("../i/decors/bottom_body_decor.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50% 50%;

			pointer-events: none;
		}
	}
}

.footer_decor_w {
	z-index: 3;

	position: absolute;
	bottom: 0;
	left: 16.8em;

	width: 47.3em;
	height: 48.7em;

	overflow: hidden;

	body.contact_mod & {
		display: none;
	}
}

.footer_in {
	@extend %global_flex_block_row_wrap_flex-start_center;

	z-index: 6;

	position: relative;
	min-height: $height_footer;

	
	@include bp($point_3) {
		flex-direction: column;
		padding: 4em 0;
	}
	
}

.footer_copy_w {
	
	@include bp($point_2, min) {
		margin-right: 8.4em;
	}

	
	@include bp($point_3) {
		order: 4;
	}
	
	
}

.footer_copy {
	color: $teal_4;
	font-family: $font_1;
	font-size: 1.5em;
	font-weight: 400;
	letter-spacing: .038rem;
}

.footer_phone_w {
	
	@include bp($point_2, min) {
		margin-right: 2em;
	}

	
	@include bp($point_3) {
		margin-bottom: 3em;
		order: 1;
	}
	
	
}

.footer_phone {
	@extend %transition_color;

	color: $white;
	font-family: $font_1;
	font-size: 3em;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .075rem;
	text-decoration: none;
	line-height: .8;

	&:hover, &:active, &:focus {
		color: $accent;
		text-decoration: none;
	}
}

.footer_nav {
	
	@include bp($point_2, min) {
		margin-left: auto;
	}

	
	@include bp($point_3) {
		margin-bottom: 3em;
		order: 2;
	}
	
	
}

.footer_nav_list {
	@extend %global_flex_block_row_wrap_flex-start;

	margin: 0 -3.2em -1em 0;
	padding: 0;

	list-style: none;

	
	@include bp($point_3) {
		display: block;
	}
	
}

.footer_nav_item {
	padding: 0 3.2em 1em 0;

	
	@include bp($point_3) {
		text-align: center;
	}
	
}

.footer_nav_link {
	@extend %transition_color;

	text-decoration: none;

	color: $teal_4;
	font-family: $font_1;
	font-size: 1.5em;
	font-weight: 400;
	line-height: .8;
	letter-spacing: .038rem;

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $accent;
	}
}

.footer_social {
	
	@include bp($point_2, min) {
		margin-left: 10.6em;
	}

	
	@include bp($point_3) {
		margin: 0 0 3em;
		order: 3;
	}
	
	
}

.social_list {
	@extend %global_flex_block_row_wrap_flex-start;

	margin: 0 -3.6em -1em 0;
	padding: 0;

	list-style: none;
}

.social_item {
	padding: 0 3.6em 1em 0;
}

.social_link {
	@extend %transition_color;

	text-decoration: none;
	display: inline-block;
	color: $white;

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $accent;
	}
}