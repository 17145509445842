.doctors_list_w {
	width: 100%;
}

.doctors_list {
	@extend %global_flex_block_row_wrap_flex-start;

	padding: 0;

	list-style: none;

	
	@include bp($point_2, min) {
		margin: 0 -7em -5em 0;
	}

	
	@include bp($point_3) {
		margin: 0 -3em -4em 0;
	}
	
	
}

.doctors_item {
	
	@include bp($point_4, min) {
		width: 50%;
	}

	
	@include bp($point_4 - 1) {
		width: 100%;
	}
	
	
	
	@include bp($point_2, min) {
		padding: 0 7em 5em 0;
	}

	
	@include bp($point_3) {
		padding: 0 3em 4em 0;
	}
	
	
}

.doctors_item_in {
	width: 100%;
}

.doctors_item_top {
	@extend %global_flex_block_row_wrap_flex-start_flex-end;

	position: relative;

	width: 100%;
	height: 35em;
	margin-bottom: 3em;
	padding: 3em 2.8em;
}

.doctors_item_img_w {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	object-fit: cover;

	&:before {
		position: absolute;
		left: 0;
		bottom: 0;

		content: '';

		width: 100%;
		height: 20.8em;
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);

	}
}

.doctors_item_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.doctors_item_bottom {
	
	@include bp($point_2, min) {
		padding: 0 3.2em;
	}
	
}

.doctors_item_info_w {
	z-index: 5;
	position: relative;

	width: 100%;
	max-width: 30em;
}

.doctors_info_title {
	color: $white;
	font-family: $font_1;
	font-size: 1.5em;
	font-weight: 400;
	line-height: (3/1.5);
	letter-spacing: .038rem;
}