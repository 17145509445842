.page_content_w {
	width: 100%;
	margin-bottom: 5.4em;

	&:last-child {
		margin-bottom: 0;
	}

	h1 {
		width: 100%;
		margin-bottom: (3em/3.5);

		color: $teal_1;
		font-size: 3.5em;
		font-weight: 700;
		line-height: (3.5/3.5);
		text-transform: uppercase;

		&:last-child {
			margin-bottom: 0;
		}

		@include bp($point_3) {
			font-size: 3em;
			line-height: (3.5/3);
		}
	}

	h2 {
		width: 100%;
		margin-bottom: (2.8em/2.5);

		color: $teal_1;
		font-size: 2.5em;
		font-weight: 700;
		line-height: (3.5/2.5);
		text-transform: uppercase;

		overflow: hidden;

		&:last-child {
			margin-bottom: 0;
		}


	}

	p {
		width: 100%;
		margin-bottom: (3em/1.7);

		font-family: $font_1;
		color: $gray_4;
		font-size: 1.7em;
		font-weight: 400;
		line-height: (2.8/1.7);

		&:last-child {
			margin-bottom: 0;
		}

		@include bp($point_3) {
			font-size: 1.4em;
			line-height: (2.8/1.3);
		}
	}

	ul {
		margin: 0 0 3em;
		padding: 0;

		list-style: none;

		&:last-child {
			margin-bottom: 0;
		}
	}

	li {
		position: relative;
		width: 100%;
		margin-bottom: (2.8em/1.7);
		padding-left: (8em/1.7);

		color: $gray_4;
		font-size: 1.7em;
		font-weight: 400;
		line-height: (2.8/1.7);

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			position: absolute;
			top: (1.4em/1.7);
			left: (2.2em/1.7);

			content: '';

			width: (3.6em/1.7);
			height: 2px;

			background-color: $teal_9;
		}
	}

	img {
		max-width: 100%;

		@include bp($point_2, min) {
			&.align_left {
				display: inline;
				float: left;
				margin-right: 7em;
				margin-bottom: 7em;
			}
	
			&.align_right {
				display: inline;
				float: right;
				margin-left: 7em;
				margin-bottom: 7em;
			}
		}

		@include bp($point_3) {
			width: 100%;
			margin-bottom: 3em;

			&:last-child {
				margin-bottom: 0;
			}
		}
		
		

	}

	div {
		@extend %global_flex_block_row_wrap_flex-start_stretch;
		margin: 0 -2em 1em 0;

		img {
			width: 100%;
			padding: 0 2em 2em 0;

			&.size_1 {
				@include bp($point_2, min) {
					width: 37.5%;
				}
			}

			&.size_2 {
				@include bp($point_2, min) {
					width: 25%;
				}
			}

			&.size_3 {
				@include bp($point_2, min) {
					width: 29.9%;
				}
			}

			@include bp($point_3) {
				margin-bottom: 0;
			}
		}

		

	}
}