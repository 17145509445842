.hero_block {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	
	@include bp($point_2, min) {
		width: 100%;
	}
	
	
	@include bp($point_3) {
		margin: 0 (-$gl_indent);
	}
	
}

.hero_block_col {
	
	@include bp($point_2, min) {
		width: 50%;
	}

	
	@include bp($point_3) {
		width: 100%;

		&:first-child {
			order: 2;
		}

		&:last-child {
			order: 1;
		}
	}
	
}

.hero_category_list {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	width: 100%;
}

.hero_category_item {
	
	@include bp($point_4, min) {
		width: 50%;
	}

	
	@include bp($point_4 - 1) {
		width: 100%;
	}
	
	

	background-color: $teal_1;

	&:nth-child(2n) {
		background-color: $teal_8;
	}

	&:nth-child(3n) {
		background-color: $teal_5;
	}

	&:nth-child(4n) {
		background-color: $teal_6;
	}
}

.hero_category_item_in {
	@extend %global_flex_block_row_wrap_center_center;

	width: 100%;
	height: 100%;
	min-height: 32.6em;
	padding: 2em;
}

.hero_category_item_content {
	width: 100%;
	max-width: 28.5em;

	text-align: center;
}

.hero_category_title {
	width: 100%;
	margin-bottom: (1.4em/2.5);

	color: $white;
	font-family: $font_1;
	font-size: 2.5em;
	font-weight: 700;
	line-height: (3.5/2.5);
	text-transform: uppercase;
	letter-spacing: .063rem;

	
	@include bp($point_4 - 1) {
		font-size: 1.8em;
	}
	
}

.hero_category_descr {
	width: 100%;
	margin-bottom: (3em/1.6);

	color: $teal_3;
	font-family: $font_1;
	font-size: 1.6em;
	font-weight: 400;
	line-height: (2.5/1.6);
	letter-spacing: .04rem;
}

.hero_category_link_w {
	width: 100%;
}

.hero_category_link {
	@extend %transition_color;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	align-content: center;

	text-decoration: none;
	color: $teal_2;


	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $white;
	}
}

.hero_category_link_title {
	margin-right: (1.4em/1.7);

	font-family: $font_1;
	font-size: 1.7em;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: .042rem;

	
	@include bp($point_4 - 1) {
		font-size: 1.4em;
	}
	
}