//---------------------------------------------fonts
$font_path: "../fonts/";
$font_1: 'SegoeUI', sans-serif;
$font_size_base: 1.6rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$gray_2: #f8f8fb;
$black: #000;
$teal_8: #4f796f;
$teal_2: #a8d5cb;
$teal_6: #2e4e47;
$gray_5: #62635e;
$teal_5: #41645c;
$gray_4: #63645f;
$teal_7: #d0e9e3;
$gray: #eeeef4;
$gray_3: #dedee2;
$teal_1: #5c8b80;
$teal_4: #aacfc6;
$teal_9: #3c6b60;
$teal_3: #8bbdb2;
$accent: #fe6043;

$color_text_base: $black;
$color_link_base:#4aa3df;
$body_bg: $white;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popap : 9999,
	header : 100,
	footer : 100
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$height_footer: 15rem;
$height_header: 13.6rem;
$page_width: 154rem;
$page_width_2: 120rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_indent: 2rem;
$gl_radius: 2rem 0;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
$point_9: 1367px;
$point_10: 1600px;
$point_11: 1440px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 4rem;
$input_offset: 0 2rem;
$input_font_size: $font_size_base;
$input_border_color: #ccc;
$input_border_color_active: $black;
$input_placeholder_color: $black;
$input_text_color: $black;
$input_radius: $gl_radius;
//---------------------------------------------forms###