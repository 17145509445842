//---------------------------------------------sections
.section {
	position: relative;

	width: 100%;

	
	@include bp($point_2, min) {
		padding: 12em $gl_indent;
	}

	
	@include bp($point_3) {
		padding: 6em $gl_indent;
	}
	
	

	font-size: 1rem;

	&.no_offset_mod {
		padding-top: 0;
		padding-bottom: 0;
	}

	&.no_top_offset {
		padding-top: 0;
	}


	&.shadow_mod {
		&:before {
			z-index: -1;

			position: absolute;
			left: 0;
			bottom: 0;

			content: '';

			width: 100%;
			height: 109em;

			background-image: url("../i/decors/body_head_decor.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50% 50%;

			pointer-events: none;
		}
	}

}

.section_in {
	max-width: $page_width;
	width: 100%;
	margin: 0 auto;

	&.limit_mod {
		max-width: $page_width_2;
	}

	body.inner_mod & {
		max-width: $page_width_2;
	}
}

.section_content_w {
	width: 100%;
	text-align: center;

	&.limit_mod {
		max-width: 91.2em;
		margin: 0 auto;
	}

	&.offset_mod {
		margin-bottom: 6.6em;
	}
}

.section_title {
	width: 100%;

	color: $teal_1;
	font-family: $font_1;
	font-size: 4em;
	font-weight: 700;
	line-height: (5.5/4);
	text-transform: uppercase;
	letter-spacing: .1rem;

	
	@include bp($point_4 - 1) {
		font-size: 2.5em;
	}
	

	&.white_mod {
		color: $white;
	}

	&.size_mod {
		font-size: 2.5em;
		font-weight: 700;
		line-height: (3.5/2.5);
		letter-spacing: .063rem;

		@include bp($point_4 - 1) {
			font-size: 1.8em;
		}
	}

	&.size_2_mod {
		font-size: 3.5em;
		font-weight: 700;
		line-height: (7/3.5);
		letter-spacing: .088rem;

		@include bp($point_4 - 1) {
			font-size: 2.5em;
		}
		
	}

	&.offset_mod {
		margin-bottom: (3.6em/4);
	}

	&.offset_size_mod {
		margin-bottom: (3.8em/2.5);
	}

	&.offset_size_2_mod {
		margin-bottom: (3em/2.5);
	}
}

.section_descr {
	width: 100%;

	color: $gray_4;
	font-family: $font_1;
	font-size: 1.7em;
	font-weight: 400;
	line-height: (2.8/1.7);
	letter-spacing: .042rem;

	&.color_mod {
		color: $teal_7;
	}

	p {
		margin-bottom: (3.4em/1.7);

		&:last-child {
			margin-bottom: 0;
		}
	}

	&.size_mod {
		font-size: 1.8em;

		p {
			margin-bottom: (3.4em/1.8);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.size_2_mod {
		font-size: 1.5em;

		p {
			margin-bottom: (2em/1.5);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.offset_mod {
		margin-bottom: (4em/1.7);
	}

	&.offset_2_mod {
		margin-bottom: (3.4em/1.7);
	}

	&.offset_size_mod {
		margin-bottom: (4.6em/1.8);
	}

	&.offset_size_2_mod {
		margin-bottom: (4.6em/1.8);
	}
}

.section_img_w {
	width: 100%;
	height: 100%;
}

.section_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.section_head {
	z-index: 2;

	position: relative;

	margin: 0 (-$gl_indent);
	padding: 3em $gl_indent;

	background-color: $teal_1;

	overflow: hidden;

	
	@include bp($point_4, min) {
		min-height: 15em;
	}
	

	&.offset_mod {
		margin-bottom: 5em;
	}

	&:before {
		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 87.4em;
		height: 100%;

		background-image: url("../i/decors/section_head_decor.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;

		pointer-events: none;
	}
}

.section_head_in {
	position: relative;

	&:after {
		position: absolute;
		top: 50%;
		left: 100%;

		content: '';

		width: 30.6em;
		height: 30.6em;
		margin: (-30.6em/2) 0 0 -17em;

		background-image: url("../i/decors/section_decor_2.png");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 50% 50%;

		pointer-events: none;

		opacity: .2;
	}
}

.section_head_bg {
	z-index: -1;

	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;

	&:before {
		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 100%;
		height: 100%;

		background-color: #ecebf0;

		opacity: .7;
	}
}

.breadcrumbs_list {
	@extend %global_flex_block_row_wrap_flex-start;

	margin: 0 -3em -1em 0;
	padding: 0;

	list-style: none;
}

.breadcrumbs_item {
	position: relative;

	padding: 0 2.6em 1em 0;

	&:before {
		position: absolute;
		top: 0;
		right: (1em/1.4);

		content: '/';

		color: #cecece;
		font-size: 1.3em;
		font-weight: 400;
	}

	&:last-child {
		padding-right: 0;
		&:before {
			display: none;
		}
	}
}

.breadcrumbs_link {
	color: #cecece;
	font-family: $font_1;
	font-weight: 400;
	font-size: 1.3em;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: .033rem;

	&.current_mod {
		text-decoration: none;

		&:hover, &:active, &:focus {
			text-decoration: none;
		}
	}

	&:hover, &:active, &:focus {
		text-decoration: underline;
	}
}