.services_block_w {
	width: 100%;
}

.services_block {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	width: 100%;
	
}

.services_block_col {

	&.img_mod {
		
		@include bp($point_2, min) {
			width: 42.34%;

			.services_block:nth-child(odd) & {
				margin-right: 2em;
			}

			.services_block:nth-child(even) & {
				margin-left: auto;
				order: 2;
			}
		}
		
		@include bp($point_3) {
			width: 100%;
			height: 50vw;
		}
	}

	&.content_mod {
		
		@include bp($point_2, min) {
			width: 52.5%;

			.services_block:nth-child(odd) & {
				margin-left: auto;
			}

			.services_block:nth-child(even) & {
				margin-right: 2em;
				order: 1;
			}
		}

		@include bp($point_3) {
			width: 100%;
		}
		
	}
}

.services_img_block {
	position: relative;

	width: 100%;
	height: 100%;
}

.services_img_block_in {
	position: absolute;
	top: 0;

	height: 100%;

	
	@include bp($point_2, min) {
		width: 50vw;

		.services_block:nth-child(odd) & {
			right: 0;
		}
	
		.services_block:nth-child(even) & {
			left: 0;
		}
	}

	
	@include bp($point_3) {
		left: (-$gl_indent);
		right: (-$gl_indent);
	}
	
}

.services_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.services_content {
	@extend %global_flex_block_row_wrap_flex-start_center;

	width: 100%;
	height: 100%;

	
	@include bp($point_2, min) {
		min-height: 65.2em;
	}
	
}

.services_content_in {
	width: 100%;
	
	@include bp($point_2, min) {
		padding: 0 4em 4em 0;
	}

	
	@include bp($point_3) {
		padding: 4em 0;
	}
	
	
}


.services_info_w {
	@extend %global_flex_block_row_wrap_flex-start_center;

	width: 100%;

	
	@include bp($point_3) {
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;
	}
}

.services_price_w {
	
	@include bp($point_2, min) {
		margin-right: 5em;
	}

	
	@include bp($point_3) {
		margin-bottom: 3em;
	}
	
	
}

.services_price {
	color: $accent;
	font-family: $font_1;
	font-weight: 400;
	line-height: .8;
	font-size: 2em;
	letter-spacing: .05rem;

	span {
		font-size: (3.5em/2);
		letter-spacing: .088rem;
	}
}