.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-arrow-right {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icon-arrow_down {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-facebook {
	font-size:(28/10)*1rem;
	width:(16/28)*1em;
}
.icon-instagram {
	font-size:(28/10)*1rem;
	width:(24/28)*1em;
}
