//---------------------------------------------icons
.icon_w {
	position: relative;

	&.arrow_mod {
		width: 1.8em;
		height: 1.6em;
	}
	&.arrow_2_mod {
		width: (1.8em/1.7);
		height: (1.6em/1.7);
		margin-left: (2em/1.7);
	}
}

.icon {
	&.size_mod {
		position: absolute;
		top: 0;
		left: 0;
		
		width: 100%;
		height: 100%;
	}
}