@mixin st_btn() {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	white-space: nowrap;
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: none;
	}

}

//color extends
%color_btn {
	font-weight: 400;
	color: $white;
	text-transform: uppercase;
	font-family: $font_1;

	background-color: $teal_1;

	transition: .3s ease;

	@include st_btn();

	&:hover, &:active, &:focus {
		background: darken($teal_1, 10%);
	}

	&.accent_mod {
		background-color: $accent;

		&:hover, &:active, &:focus {
			background: darken($accent, 10%);
		}
	}

	&.white_mod {
		color: $teal_1;

		background-color: $white;

		&:hover, &:active, &:focus {
			background: darken($white, 10%);
		}
	}

}

//size extends
%btn_size {
	height: (5.5em/1.7);
	padding: 0 (3.6em/1.7);

	line-height: (5.5em/1.7);
	font-size: 1.7em;
	letter-spacing: .042rem;

	border-radius: $gl_radius;
}