.category_list_w {
	width: 100%;
}

.category_list {
	@extend %global_flex_block_row_wrap_flex-start;

	margin: 0 -3.2em -3.2em 0;
	padding: 0;

	list-style: none;
}

.category_item {
	padding: 0 3.2em 3.2em 0;

	
	@include bp($point_2, min) {
		width: 33.33%;
	}
	
	
	@include bp($point_3) {
		width: 100%;
	}
	
	
}

.category_item_in {
	width: 100%;
	display: block;

	text-decoration: none;

	&:hover, &:active, &:focus {
		text-decoration: none;
	}
}

.category_item_img_w {
	width: 100%;
	
	margin-bottom: 2em;

	border-radius: $gl_radius;

	overflow: hidden;

	
	@include bp($point_2, min) {
		height: 20em;
	}

	
	@include bp($point_3) {
		height: 50vw;
	}
	
	
}

.category_item_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.category_item_bottom {
	position: relative;

	width: 100%;

	
	@include bp($point_2, min) {
		padding: 3em 2em 0;
	}

	
	@include bp($point_3) {
		padding: 3em 0 0;
	}
	
	

	&:before {
		@extend %transition_background;

		position: absolute;
		top: 0;
		
		
		@include bp($point_2, min) {
			left: 2em;
		}

		
		@include bp($point_3) {
			left: 0;
		}
		
		

		content: '';

		width: 15em;
		height: 2px;
		background-color: $teal_1;

		.category_item_in:hover & {
			background-color: $accent;
		}
	}
}

.category_item_title {
	@extend %transition_color;

	width: 100%;
	margin-bottom: (1em/1.7);

	color: $teal_1;
	font-family: $font_1;
	font-size: 1.7em;
	font-weight: 700;
	line-height: (2.5/1.7);
	text-transform: uppercase;
	letter-spacing: .042rem;

	.category_item_in:hover & {
		color: $accent;
	}
}

.category_item_descr {
	width: 100%;

	color: #839591;
	font-family: $font_1;
	font-size: 1.6em;
	font-weight: 400;
	line-height: (3/1.6);
	letter-spacing: .04rem;
}