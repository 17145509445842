.bnt_base {
	@extend %color_btn;
	@extend %btn_size;
}

.decor_el {
	position: absolute;

	display: block;
	width: 47.3em;
	height: 48.7em;

	background-image: url("../i/decors/section_decor.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 50% 50%;

	pointer-events: none;

	font-size: 1rem;

	&.top_mod {
		z-index: 2;

		top: -15.6em;
		left: -15.6em;

		opacity: .5;

		body.inner_mod & {
			top: -18.6em;
			left: -20.6em;
		}

		.header.scroll_mod & {
			opacity: 0;
		}
	}

	&.footer_mod {
		bottom: -17.2em;
		left: 0;

		width: 100%;
		height: 100%;
	}

	&.section_mod {
		top: -17.6em;
		right: -9.2em;
	}

	@include bp($point_3) {
		display: none;
	}
}

.body_decor {
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100vh;

	overflow: hidden;

	&:before {
		position: absolute;
		top: -10rem;
		left: 0;

		content: '';

		width: 100%;
		height: 109rem;

		background-image: url("../i/decors/body_head_decor.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;

		pointer-events: none;
	}
}

.address_text {
	width: 100%;

	text-align: center;
	color: #63645f;
	font-family: $font_1;
	font-size: 1.7em;
	font-weight: 700;
	line-height: (2.8/1.7);
	letter-spacing: .042rem;
	text-transform: uppercase;
}

.address_phone {
	width: 100%;

	text-align: center;
	color: #63645f;
	font-family: $font_1;
	font-size: 1.7em;
	font-weight: 700;
	line-height: (2.8/1.7);
	letter-spacing: .042rem;

	a {
		@extend %transition_color;
		text-decoration: none;
		color: #63645f;

		&:hover, &:active, &:focus {
			text-decoration: none;
			color: $accent;
		}
	}
}

.map_w {
	position: relative;

	margin: 0 (-$gl_indent);
	height: 57.2em;

	overflow: hidden;
}

.map_el {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}